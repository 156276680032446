// 引入 Pinia 的定义存储方法
import { defineStore } from 'pinia';
// 从 vue-router 中引入路由记录类型
import { RouteRecordRaw } from 'vue-router';
// 引入路由配置和异步路由列表
import router from '@/router';
// 引入全局状态管理实例
import { store } from '@/store';

/**
 * 过滤出用户有权限访问的路由
 * @param routes - 所有的路由列表
 * @param roles - 用户的角色列表
 * @returns 返回包含可访问路由和需要移除的路由的对象
 */
// function filterPermissionsRouters(routes: Array<RouteRecordRaw>, roles: Array<unknown>) {
//   const res = []; // 存储过滤后的路由
//   const removeRoutes = []; // 存储需要移除的路由
//   // 遍历所有路由
//   routes.forEach((route) => {
//     const children = []; // 存储子路由
//     // 如果当前路由有子路由，则遍历子路由
//     route.children?.forEach((childRouter) => {
//       // 获取子路由的角色码或名称作为权限标识
//       const roleCode = childRouter.meta?.roleCode || childRouter.name;
//       // 检查用户是否有访问该子路由的权限
//       if (roles.indexOf(roleCode) !== -1) {
//         children.push(childRouter); // 有权限则添加到可访问列表
//       } else {
//         removeRoutes.push(childRouter); // 无权限则添加到移除列表
//       }
//     });
//     // 如果存在可访问的子路由，则更新父路由的子路由列表并添加到结果中
//     if (children.length > 0) {
//       route.children = children;
//       res.push(route);
//     }
//   });
//   return { accessedRouters: res, removeRoutes }; // 返回过滤后的路由和需要移除的路由
// }

function filterPermissionsRouters(routes: Array<RouteRecordRaw>, roles: Array<unknown>) {
  const res = []; // 存储过滤后的路由
  const removeRoutes = []; // 存储需要移除的路由

  function filterRoute(route) {
    if (route.children && route.children.length > 0) {
      const children = [];
      route.children.forEach((childRouter) => {
        // 获取子路由的角色码或名称作为权限标识
        const roleCode = childRouter.meta?.roleCode || childRouter.name;
        // 检查用户是否有访问该子路由的权限
        if (roles.indexOf(roleCode) !== -1) {
          // 递归处理子路由
          const { accessed, removed } = filterRoute(childRouter);
          if (accessed) {
            children.push(accessed);
          }
          removeRoutes.push(...removed);
        } else {
          removeRoutes.push(childRouter); // 无权限则添加到移除列表
        }
      });
      // 如果存在可访问的子路由，则更新父路由的子路由列表
      if (children.length > 0) {
        route.children = children;
        return { accessed: route, removed: [] };
      }
      return { accessed: null, removed: [route] };
    }
    // 获取当前路由的角色码或名称作为权限标识
    const roleCode = route.meta?.roleCode || route.name;
    // 检查用户是否有访问该路由的权限
    if (roles.indexOf(roleCode) !== -1) {
      return { accessed: route, removed: [] };
    }
    return { accessed: null, removed: [route] };
  }

  // 遍历所有路由
  routes.forEach((route) => {
    const { accessed, removed } = filterRoute(route);
    if (accessed) {
      res.push(accessed);
    }
    removeRoutes.push(...removed);
  });

  return { accessedRouters: res, removeRoutes }; // 返回过滤后的路由和需要移除的路由
}

// 定义权限管理的 Pinia store
export const usePermissionStore = defineStore('permission', {
  // 初始化状态
  state: () => ({
    whiteListRouters: ['/', '/login', '/authorize', '/authorizeH5'], // 白名单路由，无需权限即可访问
    routers: [], // 当前用户可访问的路由
    removeRoutes: [], // 需要从路由表中移除的路由
  }),
  // 定义动作（actions）
  actions: {
    /**
     * 初始化路由，根据用户角色加载对应的路由
     * @param roles - 用户的角色列表
     */
    async initRoutes(roles: Array<unknown>, routerList: Array<RouteRecordRaw>) {
      let accessedRouters = []; // 可访问的路由
      let removeRoutes = []; // 需要移除的路由

      // 如果用户拥有特殊角色（例如：'all'），则允许访问所有路由
      if (roles.includes('all')) {
        accessedRouters = routerList;
      } else {
        // 否则，根据用户角色过滤路由
        const res = filterPermissionsRouters(routerList, roles);
        accessedRouters = res.accessedRouters;
        removeRoutes = res.removeRoutes;
      }

      // 更新状态中的可访问路由和需要移除的路由
      this.routers = accessedRouters;
      this.removeRoutes = removeRoutes;

      // 从路由表中移除没有权限访问的路由
      removeRoutes.forEach((item: RouteRecordRaw) => {
        if (router.hasRoute(item.name)) {
          router.removeRoute(item.name);
        }
      });
    },
    /**
     * 恢复路由，将之前移除的路由重新添加回路由表
     */
    async restore() {
      this.removeRoutes.forEach((item: RouteRecordRaw) => {
        router.addRoute(item); // 将每个移除的路由重新添加回路由表
      });
    },
  },
});

/**
 * 获取权限管理的 Store 实例
 * @returns 权限管理的 Store 实例
 */
export function getPermissionStore() {
  return usePermissionStore(store);
}
