<template>
  <l-side-nav
    v-if="settingStore.showSidebar"
    :show-logo="settingStore.showSidebarLogo"
    :layout="settingStore.layout"
    :is-fixed="settingStore.isSidebarFixed"
    :menu="sideMenu"
    :theme="settingStore.displayMode"
    :is-compact="settingStore.isSidebarCompact"
  />
</template>

<script lang="ts">
export default {
  name: 'LayoutSideNav',
};
</script>

<script setup lang="ts">
import { computed } from 'vue';
import { useRoute, RouteRecordRaw } from 'vue-router';
import { storeToRefs } from 'pinia';
import { usePermissionStore, useSettingStore } from '@/store';
import LSideNav from './SideNav.vue';

const route = useRoute();
const permissionStore = usePermissionStore();
const settingStore = useSettingStore();
const { routers: menuRouters } = storeToRefs(permissionStore);

// 递归函数，用于过滤掉 meta.hideInNav 为 true 的菜单项
function filterMenuItems(menu: RouteRecordRaw): RouteRecordRaw | null {
  // 如果当前菜单项的 meta.hideInNav 为 true，则不包含该菜单项
  if (menu.meta && menu.meta.hideInNav) {
    return null;
  }

  // 如果有子菜单项，递归过滤子菜单项
  if (menu.children && menu.children.length > 0) {
    const filteredChildren = menu.children.map(filterMenuItems).filter((child) => child !== null) as RouteRecordRaw[];

    if (filteredChildren.length > 0) {
      return {
        ...menu,
        children: filteredChildren,
      };
    }
    // 如果没有子菜单项了，返回当前菜单项（去掉 children 属性）
    return { ...menu, children: undefined };
  }

  // 没有子菜单项，直接返回当前菜单项
  return menu;
}

const sideMenu = computed(() => {
  const { layout, splitMenu } = settingStore;
  // let newMenuRouters = menuRouters.value;   // 取消直接使用menuRouters.value
  let newMenuRouters = [];

  // 过滤所有层级的菜单项
  newMenuRouters = menuRouters.value.map(filterMenuItems).filter((menu) => menu !== null) as RouteRecordRaw[];

  if (layout === 'mix' && splitMenu) {
    newMenuRouters = newMenuRouters.map((menu) => {
      if (route.path.indexOf(menu.path) === 0) {
        return {
          ...menu,
          children: menu.children.map((subMenu) => ({
            ...subMenu,
            path: `${menu.path}/${subMenu.path}`,
          })),
        };
      }
      return menu;
    });
  }

  return newMenuRouters;
});
</script>
