import { MessagePlugin } from 'tdesign-vue-next';
import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css'; // progress bar style

import moment from 'moment';
import { RouteRecordRaw } from 'vue-router';
import router, { asyncRouterList, asyncRouterListBackend } from '@/router';
import { getPermissionStore, getUserStore } from '@/store';

NProgress.configure({ showSpinner: false });

// 辅助函数，用于检查路由是否匹配
function checkRouteMatch(route: RouteRecordRaw, path: string, parentPath = ''): boolean {
  const url = new URL(`http://example.com${path}`);
  const { pathname } = url;
  const fullPath = parentPath + route.path;
  // console.log('Checking route match:', fullPath === path, fullPath, path);

  if (fullPath === pathname) {
    return true;
  }

  if (route.children && route.children.length > 0) {
    return route.children.some((child) => checkRouteMatch(child, pathname, `${fullPath}/`));
  }

  return false;
}

router.beforeEach(async (to, from, next) => {
  NProgress.start();

  const userStore = getUserStore();
  const permissionStore = getPermissionStore();
  const { whiteListRouters } = permissionStore;
  const { userInfo, logout, refreshToken } = userStore;

  if (userInfo.token) {
    const now = moment().valueOf();
    const timeDiff = Number(userInfo.expiresTime) - now;
    // console.log(`now：${now}`);
    // console.log(`当前时间:${moment().format('YYYY-MM-DD HH:mm:ss')}`);
    // console.log(`expiresTime：${userInfo.expiresTime}`);
    // console.log(
    //   `token失效时间:${moment(Number(userInfo.expiresTime) - 0 * 60 * 60 * 1000).format('YYYY-MM-DD HH:mm:ss')}`,
    // );
    // console.log(`timeDiff：${timeDiff}`);
    // console.log(`token剩余时间：${timeDiff / (1 * 60 * 60 * 1000)}小时`);

    // const { token } = JSON.parse(localStorage.getItem('user')).userInfo;
    // const user = JSON.parse(localStorage.getItem('user') || '{}').userInfo.name;
    // console.log('User:', user); // 检查 user 对象
    // console.log('Token:', token); // 检查 token 是否存在

    if (timeDiff > 0) {
      // 少于3小时刷新token 3 * 60 * 60 * 1000
      if (timeDiff < 3 * 60 * 60 * 1000) {
        // console.log(`更新token：refreshToken`);
        refreshToken();
      }
    } else {
      // console.log(`移除token：login`);
      logout();
    }

    if (to.path === '/login') {
      next();
      return;
    }

    const { roles } = userStore;

    // 根据当前页面路径决定使用哪个路由列表
    let RouterList: Array<RouteRecordRaw>;
    if (asyncRouterListBackend.some((route) => checkRouteMatch(route, to.fullPath))) {
      RouterList = asyncRouterListBackend;
    } else if (asyncRouterList.some((route) => checkRouteMatch(route, to.fullPath))) {
      RouterList = asyncRouterList;
    } else {
      RouterList = asyncRouterList; // 默认选择前端路由列表
    }

    if (roles && roles.length > 0) {
      await permissionStore.initRoutes(roles, RouterList); // 传入角色和路由列表
      if (router.hasRoute(to.name)) {
        next();
      } else {
        next(`/`);
      }
    } else {
      try {
        await userStore.getUserInfo();

        const { roles } = userStore;

        await permissionStore.initRoutes(roles, RouterList);

        if (router.hasRoute(to.name)) {
          next();
        } else {
          next(`/`);
        }
      } catch (error) {
        MessagePlugin.error(error);
        next({
          path: '/login',
          query: { redirect: encodeURIComponent(to.fullPath) },
        });
        NProgress.done();
      }
    }
  } else {
    /* white list router */
    if (whiteListRouters.indexOf(to.path) !== -1) {
      // console.log('userInfo222:白名单，无token，继续');
      next();
    } else {
      // console.log('userInfo333：非白名单，需要验证权限');
      next({
        path: '/login',
        query: { redirect: encodeURIComponent(to.fullPath) },
      });
    }
    NProgress.done();
  }
});

router.afterEach((to) => {
  if (to.path === '/login') {
    const userStore = getUserStore();
    const permissionStore = getPermissionStore();

    userStore.logout();
    permissionStore.restore();
  }
  NProgress.done();
});
