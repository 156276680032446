import layoutsBackend from '@/layouts-wx/index.vue';
import App from '@/assets/app.svg';
import ViewList from '@/assets/view-list.svg';
import Setting from '@/assets/setting.svg';

export default [
  {
    path: '/wx/auth',
    name: 'auth',
    component: layoutsBackend,
    redirect: '/wx/auth/authPageManage',
    meta: {
      title: '管家中心',
      icon: App,
    },
    children: [
      {
        path: 'authPageManage',
        name: 'authPageManage',
        component: () => import('@/pages-wx/auth/authPageManage/index.vue'),
        meta: {
          title: '授权链接生成器',
        },
      },
      {
        path: 'authorizedAccountManage',
        name: 'authorizedAccountManage',
        component: () => import('@/pages-wx/auth/authorizedAccountManage/index.vue'),
        meta: {
          title: '授权账号管理',
        },
        // 尝试将版本管理、提交审核放到授权账号管理下，不成功，后续填坑
        // children: [],
      },
      {
        path: 'miniProgramVersion',
        name: 'miniProgramVersion',
        component: () => import('@/pages-wx/auth/authorizedAccountManage/miniProgramVersion/index.vue'),
        meta: { title: '版本管理', hideInNav: true },
      },
      {
        path: 'submitAudit',
        name: 'submitAudit',
        component: () => import('@/pages-wx/auth/authorizedAccountManage/submitAudit/index.vue'),
        meta: { title: '提交审核', hideInNav: true },
      },
    ],
  },
  {
    path: '/wx/develop',
    name: 'develop',
    component: layoutsBackend,
    redirect: '/wx/develop/Tools',
    meta: {
      title: '开发辅助',
      icon: ViewList,
    },
    children: [
      // {
      // path: 'Tools',
      // name: 'Tools',
      // component: () => import('@/pages-wx/develop/Tools/index.vue'),
      // meta: {
      //   title: '开发调试',
      // },
      // children: [
      // ],
      // },
      {
        path: 'thirdMessage',
        name: 'thirdMessage',
        component: () => import('@/pages-wx/develop/Tools/thirdMessage/index.vue'),
        meta: {
          title: '消息推送查询',
        },
      },
      {
        path: 'thirdToken',
        name: 'thirdToken',
        component: () => import('@/pages-wx/develop/Tools/thirdToken/index.vue'),
        meta: {
          title: '在线生成 Token',
        },
      },
      {
        path: 'forwardMessage',
        name: 'forwardMessage',
        component: () => import('@/pages-wx/develop/forwardMessage/index.vue'),
        meta: {
          title: '消息转发器',
        },
      },
      {
        path: 'proxyConfig',
        name: 'proxyConfig',
        component: () => import('@/pages-wx/develop/proxyConfig/index.vue'),
        meta: {
          title: 'proxy配置',
        },
      },
    ],
  },
  {
    path: '/wx/system',
    name: 'system',
    component: layoutsBackend,
    redirect: '/wx/system/passwordManage',
    meta: {
      title: '系统管理',
      icon: Setting,
    },
    children: [
      {
        path: 'passwordManage',
        name: 'passwordManage',
        component: () => import('@/pages-wx/system/passwordManage/index.vue'),
        meta: {
          title: 'Secret与密码管理',
        },
      },
      {
        path: 'systemVersion',
        name: 'systemVersion',
        component: () => import('@/pages-wx/system/systemVersion/index.vue'),
        meta: {
          title: '系统版本',
        },
      },
    ],
  },
  {
    path: '/wx/result',
    name: 'resultBackend',
    component: layoutsBackend,
    redirect: '/wx/result/success',
    meta: {
      title: '结果页',
      icon: 'check-circle',
      hideInNav: true,
    },
    children: [
      {
        path: 'network-error',
        name: 'ResultNetworkErrorBackend',
        component: () => import('@/pages-mp/result/network-error/index.vue'),
        meta: {
          title: '网络异常',
        },
      },
      {
        path: '403',
        name: 'Result403Backend',
        component: () => import('@/pages-mp/result/403/index.vue'),
        meta: {
          title: '无权限',
        },
      },
      {
        path: '404',
        name: 'Result404Backend',
        component: () => import('@/pages-mp/result/404/index.vue'),
        meta: {
          title: '访问页面不存在页',
        },
      },
      {
        path: '500',
        name: 'Result500Backend',
        component: () => import('@/pages-mp/result/500/index.vue'),
        meta: {
          title: '服务器出错页',
        },
      },
      {
        path: 'browser-incompatible',
        name: 'ResultBrowserIncompatibleBackend',
        component: () => import('@/pages-mp/result/browser-incompatible/index.vue'),
        meta: {
          title: '浏览器不兼容页',
        },
      },
      {
        path: 'maintenance',
        name: 'ResultMaintenanceBackend',
        component: () => import('@/pages-mp/result/maintenance/index.vue'),
        meta: {
          title: '系统维护页',
        },
      },
    ],
  },
];
