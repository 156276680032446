import { AxiosHeaders } from 'axios';
import { request } from '@/utils/request';
import type { ComponentResult, AuthResult } from '@/api/model/wxModel';

export function loginRequest(userInfo: Record<string, string>) {
  return request.put<AuthResult>({
    data: userInfo,
    url: `/auth`,
    requestOptions: {
      withToken: false, // 不需要携带 token
    },
    headers: new AxiosHeaders(),
  });
}

// 第三方token
export function getComponentTokenRequest() {
  return request.get<any>({
    url: `/admin/component-access-token`,
    headers: new AxiosHeaders(),
  });
}
export function getAuthAccessTokenRequest(params: { appid: string }) {
  return request.get<any>({
    params,
    url: `/admin/authorizer-access-token`,
    headers: new AxiosHeaders(),
  });
}
export function getTicketRequest() {
  return request.get<any>({
    url: `/admin/ticket`,
    headers: new AxiosHeaders(),
  });
}

// 消息与事件
export function getAuthMessageRequest(params: {
  infoType: string;
  offset: number;
  limit: number;
  startTime: number;
  endTime: number;
}) {
  return request.get<any>({
    params,
    url: `/admin/wx-component-records`,
    headers: new AxiosHeaders(),
  });
}
export function getNormalMessageRequest(params: {
  appid?: string;
  event: string;
  msgType: string;
  offset: number;
  limit: number;
  startTime: number;
  endTime: number;
}) {
  return request.get<any>({
    params,
    url: `/admin/wx-biz-records`,
    headers: new AxiosHeaders(),
  });
}
export function getMessageConfigRequest() {
  return request.get<any>({
    url: `/admin/callback-config`,
    headers: new AxiosHeaders(),
  });
}
export function getCallbackRuleRequest(params: { type: number; offset: number; limit: number }) {
  return request.get<any>({
    params,
    url: `/admin/callback-proxy-rule-list`, // 获取，消息推送转发配置列表
    headers: new AxiosHeaders(),
  });
}
export function addCallbackRuleRequest(params: {
  name: string;
  port: string;
  data: {
    port: number;
    path: string;
  };
  open: number;
}) {
  return request.post<any>({
    params,
    url: `/admin/callback-proxy-rule`, // 新增，消息推送转发配置
    headers: new AxiosHeaders(),
  });
}
export function updateCallbackRuleRequest(params: { open: number }) {
  return request.put<any>({
    params,
    url: `/admin/callback-proxy-rule`, // 开启关闭，消息推送转发配置
    headers: new AxiosHeaders(),
  });
}
export function deleteCallbackRuleRequest(params: { id: number }) {
  return request.delete<any>({
    params,
    url: `/admin/callback-proxy-rule`, // 删除，消息推送转发配置
    headers: new AxiosHeaders(),
  });
}
export function testCallbackRuleRequest(params: { id: number }) {
  return request.post<any>({
    params,
    url: `/admin/callback-test`, // 测试消息推送转发配置
    headers: new AxiosHeaders(),
  });
}

// 授权小程序管理
export function getAuthorizedAccountRequest(params: { offset: number; limit: number; appid: string }) {
  return request.get<any>({
    params,
    url: `/admin/authorizer-list`,
    headers: new AxiosHeaders(),
  });
}

// 代开发小程序管理
export function getDevMiniProgramListRequest(params: { offset: number; limit: number; appid: string }) {
  return request.get<any>({
    params,
    url: `/admin/dev-weapp-list`, // 获取代开发小程序列表
    headers: new AxiosHeaders(),
  });
}
export function submitAuditRequest(data: {
  appid: string | string[];
  itemList: {
    id: number;
    firstClass: string;
    secondClass: string;
    thirdClass: string;
    firstId: number;
    secondId: number;
    thirdId: number;
  }[];
  previewInfo: {
    videoIdList: any[];
    picIdList: any[];
  };
  versionDesc: string;
  feedbackInfo: string;
  feedbackStuff: string;
  ugcDeclare: {};
}) {
  return request.post<any>({
    data,
    url: `/admin/submit-audit`, // 提交审核
    headers: new AxiosHeaders(),
  });
}
export function getDevVersionRequest(params: { appid: string | string[] }) {
  return request.get<any>({
    params,
    url: `/admin/dev-versions`, // 获取代开发小程序版本详情
    headers: new AxiosHeaders(),
  });
}
export function getTemplateListRequest(params: { templateType: number }) {
  return request.get<any>({
    params,
    url: `/admin/template-list`, // 拉取模板列表
    headers: new AxiosHeaders(),
  });
}
export function revokeAuditRequest(params: { appid: string | string[] }) {
  return request.post<any>({
    params,
    url: `/admin/revoke-audit`, // 撤回审核
    headers: new AxiosHeaders(),
  });
}
export function speedUpAuditRequest(params: { appid: string | string[] }) {
  return request.post<any>({
    params,
    url: `/admin/speed-up-audit`, // 加急审核
    headers: new AxiosHeaders(),
  });
}
export function commitCodeRequest(
  params: { appid: string | string[] },
  data: { templateId: string; extJson: string; userVersion: string; userDesc: string },
) {
  return request.post<any>({
    params,
    data,
    url: `/admin/commit-code`, // 提交代码
    headers: new AxiosHeaders(),
  });
}
export function releaseCodeRequest(params: { appid: string | string[] }) {
  return request.post<any>({
    params,
    url: `/admin/release-code`, // 发布代码
    headers: new AxiosHeaders(),
  });
}
export function uploadFileRequest(params: { appid: string | string[]; type: string }, data: { formData: FormData }) {
  return request.post<any>({
    params,
    data: data.formData,
    url: `/admin/upload-media`, // 修改服务状态
    headers: new AxiosHeaders(),
  });
}
export function changeServiceStatusRequest(appId, data: { action: string }) {
  return request.post<any>({
    data,
    url: `/admin/change-visit-status?appid=${appId}`, // 修改服务状态
    headers: new AxiosHeaders(),
  });
}
export function rollbackReleaseRequest(params: { appid: string | string[] }) {
  return request.post<any>({
    params,
    url: `/admin/rollback-release-version`, // 拉取模板列表
    headers: new AxiosHeaders(),
  });
}

export function getCategoryRequest(params: { appid: string | string[] }) {
  return request.get<any>({
    params,
    url: `/admin/category`, // 查询类目
    headers: new AxiosHeaders(),
  });
}
export function getQrcodeRequest(params: { appid: string }) {
  return request.get<any>({
    params,
    url: `/admin/qrcode`, // 获取小程序码
    headers: new AxiosHeaders(),
  });
}

// 设置
export function setSecretRequest(params: { secret: string | number }) {
  return request.post<any>({
    params,
    url: `/admin/secret`,
    headers: new AxiosHeaders(),
  });
}
export function getSecretRequest() {
  return request.get<any>({
    url: `/admin/secret`,
    headers: new AxiosHeaders(),
  });
}
export function updateComponentInfoRequest(data: Record<string, string>) {
  return request.post<ComponentResult>({
    data,
    url: `/admin/componentinfo`,
    headers: new AxiosHeaders(),
  });
}

// 用户管理
export function changeUserNameRequest(params: { username: string | number }) {
  return request.post<any>({
    params,
    url: `/admin/username`,
    headers: new AxiosHeaders(),
  });
}
export function changePasswordRequest(params: { password: string; oldPassword: string }) {
  return request.post<any>({
    params,
    url: `/admin/userpwd`,
    headers: new AxiosHeaders(),
  });
}

// 刷新token
export function refreshTokenRequest() {
  return request.get<AuthResult>({
    url: `/admin/refresh-auth`,
    headers: new AxiosHeaders(),
  });
}

// 转发设置
export function getProxyConfigRequest() {
  return request.get<any>({
    url: `/admin/proxy`, // 获取代理配置
    headers: new AxiosHeaders(),
  });
}
export function updateProxyConfigRequest(params: { open: boolean; port: number }) {
  return request.post<any>({
    params,
    url: `/admin/proxy`, // 更新代理配置
    headers: new AxiosHeaders(),
  });
}

// Routers 路由
export function getPreAuthCodeRequest() {
  return request.get<any>({
    url: `/authpage/preauthcode`,
    headers: new AxiosHeaders(),
  });
}

export function getComponentInfoRequest() {
  return request.get<ComponentResult>({
    url: `/authpage/componentinfo`,
    headers: new AxiosHeaders(),
  });
}
