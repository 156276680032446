<template>
  <t-breadcrumb :max-item-width="'150'" class="tdesign-breadcrumb">
    <t-breadcrumbItem v-for="item in crumbs" :key="item.to">
      {{ item.title }}
    </t-breadcrumbItem>
  </t-breadcrumb>
</template>

<script lang="ts">
export default {
  name: 'LBreadcrumb',
};
</script>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();

// 定义一个响应式的 crumbs 变量
const crumbs = ref([]);

// 计算 crumbs 的函数
const calculateCrumbs = () => {
  const pathArray = route.path.split('/');
  pathArray.shift();
  const breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
    // 如果路由下有 hiddenBreadcrumb 或当前遍历到参数则隐藏
    if (route.matched[idx]?.meta?.hiddenBreadcrumb || Object.values(route.params).includes(path)) {
      return breadcrumbArray;
    }

    // 检查 meta.title 是否存在且不为空
    const title = route.matched[idx]?.meta?.title; // ?? path
    if (title !== undefined && title !== '') {
      breadcrumbArray.push({
        path,
        to: breadcrumbArray[idx - 1] ? `/${breadcrumbArray[idx - 1].path}/${path}` : `/${path}`,
        title,
      });
    }
    return breadcrumbArray;
  }, []);
  crumbs.value = breadcrumbs;
};

// 初始化 crumbs
calculateCrumbs();

// 监听路由变化
watch(
  () => route.path,
  (newPath, oldPath) => {
    calculateCrumbs();
  },
);
</script>
<style scoped>
.tdesign-breadcrumb {
  margin-bottom: 24px;
}
</style>
