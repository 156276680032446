import Layout from '@/layouts-mp/index.vue';
import LogoutIcon from '@/assets/assets-slide-logout.svg';

export default [
  {
    path: '/mp/user',
    name: 'user',
    component: Layout,
    redirect: '/mp/user/index',
    meta: { title: '个人页', icon: 'user-circle', hideInNav: true },
    children: [
      {
        path: 'index',
        name: 'UserIndex',
        component: () => import('@/pages-mp/user/index.vue'),
        meta: { title: '个人中心' },
      },
    ],
  },
  {
    path: '/loginRedirect',
    name: 'loginRedirect',
    redirect: '/login',
    meta: { title: '登录页', icon: LogoutIcon, hideInNav: true },
    component: () => import('@/layouts-mp/blank.vue'),
    children: [
      {
        path: 'index',
        redirect: '/login',
        component: () => import('@/layouts-mp/blank.vue'),
        meta: { title: '登录中心' },
      },
    ],
  },
];
