import layoutsBackend from '@/layouts-wx/index.vue';
import DashboardIcon from '@/assets/assets-slide-dashboard.svg';

export default [
  {
    path: '/wx/dashboard',
    component: layoutsBackend,
    redirect: '/wx/dashboard/base',
    name: 'dashboardBackend',
    meta: { title: '仪表盘', icon: DashboardIcon },
    children: [
      {
        path: 'base',
        name: 'DashboardBaseBackend',
        // component: () => import('@/pages-wx/home/index.vue'),
        component: () => import('@/pages-mp/dashboard/base/index.vue'),
        meta: { title: '概览仪表盘' },
      },
    ],
  },
];
