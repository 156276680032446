import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 540.07 421.28"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("rect", {
      style: {"fill":"#0052d9"},
      width: "540.07",
      height: "421.28",
      rx: "51.19"
    }, null, -1 /* HOISTED */),
    _createElementVNode("path", {
      style: {"fill":"#fff"},
      d: "M308.54 72.15h1.2a11.54 11.54 0 0 0 1.53.23l15 .31 18.7.41c12.83.27 25.66.57 38.5.76a31.88 31.88 0 0 1 8 1.13 111.64 111.64 0 0 1 50.77 29.79c10.07 10.22 18.64 21.7 26.62 33.61a92 92 0 0 1 10.29 19.18 24.82 24.82 0 0 1 1.78 7.26c.34 7.19.3 14.39.43 21.59 0 1.27.14 2.54.21 3.81v14.88a9.53 9.53 0 0 0-.18 1.29q-.19 20.68-.37 41.39a31.09 31.09 0 0 1-1.35 9.07 169.05 169.05 0 0 1-21.45 44 53.65 53.65 0 0 1-5.72 7.83c-10.74 10.95-22.11 21.2-34.78 29.91-5.25 3.61-10.69 6.86-16.81 8.83-1.82.58-3.56 1.37-5.35 2a4.53 4.53 0 0 1-1.5.28H293.64a4.78 4.78 0 0 1-1.64-.26 174.27 174.27 0 0 1-22.16-10.24 44.33 44.33 0 0 1-10.75-8.13c-4.85-5.1-9.92-10-14.9-15-.36-.35-.73-.69-1.17-1.1-.35.4-.62.68-.87 1l-20 24.58a5.51 5.51 0 0 1-1.16 1.2c-5.58 3.68-11.79 6.15-18.39 6.17-47.72.18-95.43.1-143.15.1h-1.4v-54.2c0-1.74 0-1.74 1.74-1.74H187.1a9.9 9.9 0 0 0 2-.08c5.3-1.2 10.56-2.6 15.35-5.24a41.27 41.27 0 0 0 5.95-4.36 4 4 0 0 0 1.28-3.33V144.8a12.06 12.06 0 0 0-8.56-12.21 19.45 19.45 0 0 0-5.74-1q-68.82-.08-137.64 0h-1.69V73.7c0-.52 0-1-.07-1.55h128.4c.39.09.76.21 1.15.27 1.93.3 3.88.47 5.78.89a63 63 0 0 1 25 12.31 98.05 98.05 0 0 1 22 24.84c.31.48.66.93 1.06 1.49l1.14-1.48a142.14 142.14 0 0 1 20.13-22c4.79-4.15 9.86-7.9 15.79-10.29a82.77 82.77 0 0 1 27.61-5.78c.86-.01 1.68-.16 2.5-.25Zm-12.11 140.38h.24v2l.48 45.11c.1 9.79.21 19.59.25 29.39 0 1.18.37 1.59 1.5 1.46a9.71 9.71 0 0 1 1.08 0h49.19a21.46 21.46 0 0 0 6.71-1c21.47-7 36.59-21 45.68-41.55a5.52 5.52 0 0 0 .4-1.25 199.14 199.14 0 0 0 2.89-31.69 189.47 189.47 0 0 0-1.49-27.26 100.76 100.76 0 0 0-2.08-12.13c-2.68-10.55-8.58-19.28-16.61-26.39-3.82-3.38-8.43-5.91-12.83-8.57a38 38 0 0 0-14.69-5.55c-3.67-.47-7.41-.5-11.12-.61-9.27-.3-18.54-.53-27.81-.8-2.84-.08-5.68-.07-8.51-.3a32.28 32.28 0 0 0-12.89 1.17c-.79.26-1.09.56-1.07 1.4.1 3.63.16 7.27.2 10.91q.26 22.54.47 45.1c.06 6.88.01 13.72.01 20.56Z",
      transform: "translate(.25 -.31)"
    }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }