import Layout from '@/layouts-mp/index.vue';
// import ListIcon from '@/assets/assets-slide-list.svg';
// import FormIcon from '@/assets/assets-slide-form.svg';
// import DetailIcon from '@/assets/assets-slide-detail.svg';

export default [
  {
    path: '/mp/result',
    name: 'result',
    component: Layout,
    redirect: '/mp/result/success',
    meta: {
      title: '结果页',
      icon: 'check-circle',
      hideInNav: true,
    },
    children: [
      {
        path: 'network-error',
        name: 'ResultNetworkError',
        component: () => import('@/pages-mp/result/network-error/index.vue'),
        meta: {
          title: '网络异常',
        },
      },
      {
        path: '403',
        name: 'Result403',
        component: () => import('@/pages-mp/result/403/index.vue'),
        meta: {
          title: '无权限',
        },
      },
      {
        path: '404',
        name: 'Result404',
        component: () => import('@/pages-mp/result/404/index.vue'),
        meta: {
          title: '访问页面不存在页',
        },
      },
      {
        path: '500',
        name: 'Result500',
        component: () => import('@/pages-mp/result/500/index.vue'),
        meta: {
          title: '服务器出错页',
        },
      },
      {
        path: 'browser-incompatible',
        name: 'ResultBrowserIncompatible',
        component: () => import('@/pages-mp/result/browser-incompatible/index.vue'),
        meta: {
          title: '浏览器不兼容页',
        },
      },
      {
        path: 'maintenance',
        name: 'ResultMaintenance',
        component: () => import('@/pages-mp/result/maintenance/index.vue'),
        meta: {
          title: '系统维护页',
        },
      },
    ],
  },
];
