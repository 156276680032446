import { defineStore } from 'pinia';
import { md5 } from 'js-md5';
import moment from 'moment';
// import { TOKEN_NAME } from '@/config/global';
import { store, usePermissionStore } from '@/store';
import { loginRequest, refreshTokenRequest } from '@/api/wx';
import { asyncRouterList } from '@/router';

const InitUserInfo = {
  token: '', // || 'main_token', // 默认token不走权限
  roles: [],
  expiresTime: '',
  name: '',
};

export const useUserStore = defineStore('user', {
  state: () => ({
    // token: '', // || 'main_token', // 默认token不走权限
    userInfo: { ...InitUserInfo },
  }),
  getters: {
    roles: (state) => {
      return state.userInfo?.roles;
    },
  },
  actions: {
    async login(userInfo: Record<string, string>) {
      const mockLogin = async (userInfo: Record<string, string>) => {
        const val = { username: userInfo.username, password: md5(String(userInfo.password)) };
        // try {
        const { data } = await loginRequest(val);
        if (data.code === 0) {
          return {
            code: data.code,
            user: val.username,
            message: data.errorMsg === 'OK' ? '登陆成功' : data.errorMsg,
            data: data.data.jwt,
          };
        }
        return {
          code: data.code,
          message: data.errorMsg,
          data: '',
        };
      };

      const res = await mockLogin(userInfo);
      if (res.code === 0) {
        this.info = res;
        // this.token = res.data; // 想将token直接存在userinfo里，这行注释掉，会导致整个存储失败，这是什么原因？
        this.userInfo = { ...this.userInfo, token: res.data }; // 将token合并到userInfo对象中
      } else {
        throw res;
      }
    },
    async getUserInfo() {
      const mockRemoteUserInfo = async (info: any) => {
        // console.log(info);
        if (info.user === 'root' || info.user === 'wxcomponent') {
          return {
            name: info.user,
            roles: ['all'], // 全部权限
            // roles: [
            //   'DashboardBase', // 必须包含首页
            //   'authPageManage',
            //   'authorizedAccountManage',
            //   'miniProgramVersion',
            //   'submitAudit',
            //   'Tools',
            //   'thirdMessage',
            //   'thirdToken',
            //   'forwardMessage',
            //   'proxyConfig',
            //   'passwordManage',
            //   'systemVersion',
            // ],
            expiresTime: String(moment().add(12, 'hours').valueOf()),
            token: info.data,
          };
        }
        return {
          name: info.user,
          roles: ['UserIndex', 'DashboardBase', 'login'], // 指定权限
          expiresTime: String(moment().add(12, 'hours').valueOf()),
          token: info.data,
        };
      };

      const res = await mockRemoteUserInfo(this.info);

      this.userInfo = res;
    },
    async logout() {
      localStorage.clear();
      // this.info = '';
      // this.token = '';
      this.userInfo = { ...InitUserInfo };
    },
    async removeToken() {
      // this.info = '';
      // this.token = '';
      this.userInfo = { ...InitUserInfo };
    },
    async refreshToken() {
      const { data } = await refreshTokenRequest();
      // console.log(data);
      if (data.code === 0) {
        this.userInfo = {
          name: String(JSON.parse(localStorage.getItem('user')).userInfo.name),
          roles: String(JSON.parse(localStorage.getItem('user')).userInfo.roles),
          expiresTime: String(moment().add(12, 'hours').valueOf()),
          token: data.data.jwt,
        };
        console.log(`更新成功`);
      } else {
        localStorage.clear();
        this.userInfo = { ...InitUserInfo };
        console.log(`更新失败`);
      }
    },
  },
  persist: {
    afterRestore: (ctx) => {
      if (ctx.store.roles && ctx.store.roles.length > 0) {
        const permissionStore = usePermissionStore();
        permissionStore.initRoutes(ctx.store.roles, asyncRouterList);
      }
    },
  },
});

export function getUserStore() {
  return useUserStore(store);
}
